import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  basicTextFormattingStyles,
  useEventConfig,
} from "../../../../Components";

export const Footer = ({ ...props }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { footer } = useEventConfig();
  return (
    <Wrapper {...props}>
      {!!footer && (
        <CustomFooterContainer dangerouslySetInnerHTML={{ __html: footer }} />
      )}
      <Container>
        © {new Date().getFullYear()} SET. All rights reserved.
        <p>
          {t("Use of this site constitutes acceptance of our")}{" "}
          <span onClick={() => navigate("/terms", { state: { back: true } })}>
            User Agreement
          </span>{" "}
          {t("and")}{" "}
          <span onClick={() => navigate("/privacy", { state: { back: true } })}>
            Privacy Policy
          </span>
          {".  "}
          <span onClick={() => (window as any).Cookiebot?.renew()}>
            Cookie Settings
          </span>
        </p>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
`;

const Container = styled.div`
  text-align: center;
  color: inherit;
  font-size: 10px;
  padding: 0 0 10px;
  line-height: 15px;
  font-weight: 100;
  font-family: var(--template-font-family);
  span {
    text-decoration: underline;
    cursor: pointer;
    color: var(--template-primary);
  }
`;

const CustomFooterContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding: 0 30px 16px 30px;
  ${basicTextFormattingStyles};
  a {
    text-decoration: underline;
    cursor: pointer;
    color: var(--template-primary);
  }
`;
