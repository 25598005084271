import { BrowserRouter as Router } from "react-router-dom";
import { Theme } from "./melodies-source/Theme";
import { Routes } from "./Routes";
import { HelmetProvider } from "react-helmet-async";
import {
  HistoryProvider,
  LocationProvider,
  PageLoadingProvider,
  UserProvider,
  UserActionTrackingProvider,
} from "./Components";
import LogRocket from "logrocket";
import { LocationTracking } from "./Components/LocationTracking";

/*
if (process.env.REACT_APP_ENV !== "dev") {
  if (Math.random() <= 0.2) {
    LogRocket.init("fyqxo2/set-fresh");
  }
}
*/

function App() {
  return (
    <PageLoadingProvider>
      <Router>
        <HistoryProvider>
          <Theme>
            <UserProvider>
              <HelmetProvider>
                <UserActionTrackingProvider>
                  <LocationProvider>
                    <LocationTracking />
                    <Routes />
                  </LocationProvider>
                </UserActionTrackingProvider>
              </HelmetProvider>
            </UserProvider>
          </Theme>
        </HistoryProvider>
      </Router>
    </PageLoadingProvider>
  );
}

export default App;
